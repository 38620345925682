<template>
  <div style="position: relative; height: calc(100vh - 100px)">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="agent_count">
      全网共有：{{ boxAgentArea }} 个区域代理、{{ boxAgentCity }} 个市级代理、{{
        boxAgentProvince
      }}
      个省级代理
    </div>

    <div class="agent_table">
      <div class="search">
        <el-select
          v-model="valueArea"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 220px; margin-right: 10px"
          v-if="internationType"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option>
        </el-select>
        <div class="search_cz">
          <el-input
            placeholder="请输入手机号"
            v-model="phone"
            @keyup.enter.native="searchList"
          ></el-input>
          <i class="icon-sousuo iconfont" @click="searchList"></i>
        </div>
      </div>

      <div class="table_con">
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{
            background: 'rgba(250, 250, 252, 1)',
            color: '#4b4b4b',
          }"
        >
          <el-table-column type="index" label="序号" width="80" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="姓名" align="center">
          </el-table-column>
          <el-table-column
            prop="phoneArea"
            label="区号"
            width="80"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="phone" label="手机号" align="center">
          </el-table-column>
          <el-table-column prop="agentLevel" label="级别" align="center">
            <template slot-scope="scope">
              {{
                scope.row.isTemp == false || scope.row.agentLevel == "非代理"
                  ? scope.row.agentLevel
                  : "临时" + "-" + scope.row.agentLevel
              }}
              <div></div>
              <el-tooltip placement="bottom">
                <div slot="content" v-if="scope.row.isTemp == false">
                  成为真代理时间：{{ format_time_date(scope.row.agentTime) }}
                </div>
                <div slot="content" v-if="scope.row.isTemp == true">
                  开始时间：{{ format_time_date(scope.row.startTime) }}<br />
                  结束时间：{{ format_time_date(scope.row.endTime) }}
                </div>
                <el-button type="text" size="small">详情</el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="hardwareId" label="盒子id" align="center">
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100"
            align="center"
          >
            <template slot-scope="scope"
              ><el-button
                type="text"
                size="small"
                @click="handleDetail(scope.row)"
                >查看详情</el-button
              ></template
            >
          </el-table-column>
        </el-table>

        <Pagination
          v-show="pageLength >= 0"
          :total="pageLength"
          :limit.sync="pageSize"
          @pagination="handlePageChange"
          :page-sizes="pageSizes"
          :page="currentPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },

  data() {
    return {
      valueArea: "",
      options: [],
      phone: "",
      tableData: [],

      boxAgentArea: "",
      boxAgentCity: "",
      boxAgentProvince: "",

      agentLevel: 0,

      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "代理/运营",
        },
        {
          name: "用户代理级别查询",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const optCount = {
      url: reqUrl.agentCount,
      method: "POST",
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.boxAgentArea = res.data.data.boxAgentArea;
          this.boxAgentCity = res.data.data.boxAgentCity;
          this.boxAgentProvince = res.data.data.boxAgentProvince;
          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(optCount);

    showLoading();
    const opt = {
      url: reqUrl.getBoxAgentList,
      method: "POST",
      params: JSON.stringify({
        phone: "",
        phoneArea: this.phoneAreaROLE,
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          res.data.data.list.map((item) => this.changeItem(item));
          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.getBoxAgentList,
        method: "POST",
        params: JSON.stringify({
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            res.data.data.list.map((item) => this.changeItem(item));
            hideLoading();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getBoxAgentList,
        method: "POST",
        params: JSON.stringify({
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            res.data.data.list.map((item) => this.changeItem(item));
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;

            hideLoading();
          }
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleDetail(row) {
      if (row.agentLevel == "非代理") {
        this.agentLevel = 0;
      } else if (row.agentLevel == "区域代理") {
        this.agentLevel = 1;
      } else if (row.agentLevel == "市级代理") {
        this.agentLevel = 2;
      } else if (row.agentLevel == "省级代理") {
        this.agentLevel = 3;
      }
      this.$router.push({
        path: "/AgentIndex/detail",
        query: {
          name: row.name,
          phone: row.phone,
          phoneArea: row.phoneArea,
          userId: row.userId,
          hardwareId: row.hardwareId,
        },
      });
    },

    changeItem(item) {
      if (item.agentLevel == 0) {
        item.agentLevel = "非代理";
      } else if (item.agentLevel == 1) {
        item.agentLevel = "区域代理";
      } else if (item.agentLevel == 2) {
        item.agentLevel = "市级代理";
      } else if (item.agentLevel == 3) {
        item.agentLevel = "省级代理";
      }
    },

    format_time_date(d) {
      if (d !== null) {
        var date = new Date(d);
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        var d = date.getDate();
        d = d < 10 ? "0" + d : d;
        var currentdate = y + "-" + m + "-" + d;
        var hh = date.getHours();
        hh = hh < 10 ? "0" + hh : hh;
        var mm = date.getMinutes();
        mm = mm < 10 ? "0" + mm : mm;
        var ss = date.getSeconds();
        ss = ss < 10 ? "0" + ss : ss;
        var time = hh + ":" + mm + ":" + ss;
        return currentdate + " " + time;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.agent_count {
  height: 60px;
  border-radius: 20px;
  background: #fff;
  line-height: 60px;
  padding-left: 20px;
}

.agent_table {
  background: #fff;
  border-radius: 20px;
  margin-top: 10px;
  padding: 20px;

  .search {
    display: flex;
    align-items: center;

    ::v-deep .el-input--suffix .el-input__inner {
      border-radius: 10px;
    }

    .search_cz {
      display: flex;
      align-items: center;
      width: 250px;
      border: 1px solid #dcdfe6;
      border-radius: 10px;

      ::v-deep.el-input {
        width: 220px;

        .el-input__inner {
          border: none;
          border-radius: 10px;
        }
      }

      i {
        cursor: pointer;
      }
    }
  }

  .table_con {
    height: 100%;
    border: 1px #ebedf2 solid;
    border-radius: 10px;

    .el-table {
      border-radius: 10px;
    }
  }
}
</style>
